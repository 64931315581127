.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-datepicker-popper {
  z-index: 9999 !important;
  width: auto;
}

.react-datepicker-wrapper {
  max-width: 200px
}

@media (max-width: 599px) {

  .react-datepicker {
    display: flex !important;
    flex-direction: column !important;
    width:100%;
  }

  .react-datepicker__time-container {
    float: right;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    border-left: 0px !important;
    width: 100% !important;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 1px !important;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    box-sizing: content-box;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 100% !important;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      border-bottom-right-radius: 0.3rem;
  }

  .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: none !important;
    height: 0;
    width: 0;
    content: "";
    z-index: -1;
    border-width: none;
    left: 8 !important;
  }

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: disabled !important;
    border-bottom-color: #f0f0f0;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
