
  .ql-container {
    min-height: 10em;
    /* border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em; */
    background: #fefcfc;
    border: 1px solid #ccc;
    border-top: 0;
  }
  
  .ql-toolbar {
    background: #eaecec;
    /* border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em; */
  }
  
  #toolbar {
    background: #eaecec;
    /* border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em; */
    border: 1px solid #ccc;
    padding: 0.75em;
  }
  